
export type Listener<ev_v_t> = (args: ev_v_t) => void;
export type AsyncListener<ev_v_t> = (args: ev_v_t) => Promise<void>;

export class EventEmitter<ev_n_t extends string, ev_v_t> {
    private events: { [key in ev_n_t]?: Listener<ev_v_t>[] } = {};

    on(event: ev_n_t, listener: Listener<ev_v_t>) {
        this.events[event] = this.events[event] || [];
        this.events[event]!.push(listener);
    }

    emit(event: ev_n_t, args: ev_v_t) {
        this.events[event]?.forEach(listener => listener(args));
    }

    off(event: ev_n_t, listener: Listener<ev_v_t>) {
        this.events[event] = this.events[event]?.filter(l => l !== listener);
    }
};

export class AsyncEventEmitter<ev_n_t extends string, ev_v_t> {
    private events: { [key in ev_n_t]?: AsyncListener<ev_v_t>[] } = {};

    on(event: ev_n_t, listener: AsyncListener<ev_v_t>) {
        this.events[event] = this.events[event] || [];
        this.events[event]!.push(listener);
    }

    async emit(event: ev_n_t, args: ev_v_t) {
        const promises = this.events[event]?.map(listener => listener(args)) || [];
        await Promise.all(promises);
    }

    off(event: ev_n_t, listener: AsyncListener<ev_v_t>) {
        this.events[event] = this.events[event]?.filter(l => l !== listener);
    }
};
