import React, { useEffect, useRef } from 'react';
import NavBar from '../components/navbar';
import './Index.css';
import '../Background.css';
import { LangDelivrCt } from '../lib/lang_delivr_ct';
import { getOrLoad } from '../lib/common';

const Index = () => {
  // eslint-disable-next-line
  const t = new LangDelivrCt();
  const Loaded = useRef(false);

  useEffect(() => {
    if (!Loaded.current) {
      Loaded.current = true;
      (async (t: LangDelivrCt) => {
        const v1 = await getOrLoad();
        t.initialize(v1);
      })(t);
    }
    return () => {
      const v1=document.getElementById("index_sroot");
      if (Loaded.current&&!(v1 instanceof HTMLElement)) {
        Loaded.current = false;
        t.finalize();
      }
    }
  }, [t]);

  return (
    <main className="bg-image d-flex align-items-center">
      <div className="fixed-top">
        <NavBar />
      </div>

      <div id="index_sroot" className="container zx_page_index_l5_intro">
        <h1>{t.keyof("Index Page Header")}</h1>
        <span>{t.keyof("Index Page Body")}</span>
      </div>
    </main>
  );
};

export default Index;