
import React, { useCallback, useEffect, useRef, useState } from 'react';
import NavBar from '../components/navbar';
import './Home.css';
import '../Background.css';
import { LangDelivrCt } from '../lib/lang_delivr_ct';
import { getOrLoad } from '../lib/common';

async function notebook_fetch() {
  const v1 = await getOrLoad();
  const v2 = v1.session_key;
  if (null == v2 || v2.length === 0) {
    return undefined;
  }
  const v3 = await fetch(v1.api_url + "/client/user/get_notebook", {
    "method": "POST",
    "body": v2
  })
  if (!v3.ok) {
    return undefined;
  }
  return await v3.text();
};

async function notebook_store(p1: string) {
  const v1 = await getOrLoad();
  const v2 = v1.session_key;
  if (null == v2 || v2.length === 0) {
    return false;
  }
  const v3 = await fetch(v1.api_url + "/client/user/set_notebook", {
    "method": "POST",
    "body": JSON.stringify({
      k: v2,
      t: p1
    })
  })
  return v3.ok;
};

async function rmcall_checkin() {
  const v1 = await getOrLoad();
  const v2 = await fetch(v1.api_url + "/client/user/checkin", {
    "method": "POST",
    "body": JSON.stringify({
      k: v1.session_key
    })
  });
  return v2.ok;
};

const Home = () => {
  // eslint-disable-next-line
  const t = new LangDelivrCt();
  const notebook_data = useRef<void | HTMLTextAreaElement>();
  const Loaded = useRef(false);
  const [CheckInStatusR, CheckInStatusN] = useState(0);
  const [CheckInMsgR, CheckInMsgN] = useState("");

  const notebook_reset = useCallback(async () => {
    const v1 = await notebook_fetch();
    if (typeof v1 != "string" || v1.length === 0) {
      return;
    }
    if (!(notebook_data.current instanceof HTMLTextAreaElement)) {
      return;
    }
    notebook_data.current.value = v1;
  }, [notebook_data]);

  const notebook_save = useCallback(async () => {
    if (!(notebook_data.current instanceof HTMLTextAreaElement)) {
      return;
    }
    await notebook_store(notebook_data.current.value);
  }, [notebook_data]);

  const checkin_update = useCallback(async () => {
    const v1 = await getOrLoad();
    if (typeof v1.user_baseinfo.ciw !== "number") {
      return;
    }
    if ((v1.user_baseinfo.ciw - 1000) <= Date.now()) {
      CheckInStatusN(1);
    } else {
      const v2 = v1.user_baseinfo.ciw - Date.now();
      let v3 = "";
      {
        const v4 = Math.floor(v2 / (1000 * 60 * 60 * 24));
        if (v4 > 0) {
          v3 += ' ' + v4.toString() + "d";
        }
      }
      {
        const v4 = Math.floor((v2 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        if (v4 > 0) {
          v3 += ' ' + v4.toString() + "h";
        }
      }
      {
        const v4 = Math.floor((v2 % (1000 * 60 * 60)) / (1000 * 60));
        if (v4 > 0) {
          v3 += ' ' + v4.toString() + "m";
        }
      }
      {
        const v4 = Math.floor((v2 % (1000 * 60)) / 1000);
        if (v4 > 0) {
          v3 += ' ' + v4.toString() + "s";
        }
      }
      CheckInMsgN(v3);
      CheckInStatusN(2);
    }
  }, [CheckInMsgN, CheckInStatusN]);

  const checkin_run = useCallback(async () => {
    await rmcall_checkin();
    const v1 = await getOrLoad();
    await v1.refreshLoginData();
    if ((v1.user_baseinfo.ciw - 1000) <= Date.now()) {
      await new Promise(v2=>setTimeout(v2,1000));
      await rmcall_checkin();
      await v1.refreshLoginData();
    }
    await checkin_update();
  }, [checkin_update]);

  useEffect(() => {
    if (!Loaded.current) {
      Loaded.current = true;
      (async (t: LangDelivrCt) => {
        const v1 = await getOrLoad();
        if (null == v1.user_baseinfo) {
          // impossible here
          console.error("unexcept userinfo load status");
          await v1.refreshLoginData();
        }
        t.initialize(v1);
        await Promise.all([
          checkin_update(),
          notebook_reset()
        ]);
      })(t);
    }
    return () => {
      const v1 = document.getElementById("home_sroot");
      if (Loaded.current && !(v1 instanceof HTMLDivElement)) {
        Loaded.current = false;
        CheckInStatusN(0);
        t.finalize();
      }
    }
  }, [t, Loaded, notebook_reset, CheckInStatusN, checkin_update]);

  t.forceLoad([
    "Check In",
    "Index Page Check In Success"
  ]);

  return (
    <div className="bg-image" style={{ height: '100vh' }}>
      <NavBar />

      <div id="home_sroot" className="row d-flex g-0 p-0 m-0 overflow-auto" style={{ maxHeight: '100%', overflowY: 'auto' }}>

        <div className="col-md-4 text-center fs-4">
          <div className="text-white">
            <i className="bi bi-pencil-square"></i>
            <span> </span>
            <span>{t.keyof("Notebook")}</span>
          </div>
          <div className="">
            <textarea className="form-control" id="notebook_input" ref={notebook_data}></textarea>
          </div>
          <div className="">
            <button className="btn btn-outline-danger me-2" onClick={notebook_reset}>
              <i className="bi bi-arrow-repeat"></i>
              <span> </span>
              <span>{t.keyof("Reset")}</span>
            </button>
            <button className="btn btn-outline-success" onClick={notebook_save}>
              <i className="bi bi-save"></i>
              <span> </span>
              <span>{t.keyof("Save")}</span>
            </button>
          </div>
        </div>

        <div className="col-md-4">
          <div hidden={1 !== CheckInStatusR} className="text-center">
            <button type="button" className="btn btn-outline-warning" onClick={checkin_run}>{t.keyof("Check In")}</button>
          </div>
          <div hidden={2 !== CheckInStatusR} className="text-center fs-4 text-light">
            <p>{t.keyof("Index Page Check In Success")}</p>
            <p>{CheckInMsgR}</p>
          </div>
        </div>

        <div className="col-12" style={{ height: '6rem' }}></div>
      </div>
    </div>
  );
};

export default Home;