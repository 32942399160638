import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { getOrLoad } from '../lib/common';

async function sso_step_1() {
    const v1 = await getOrLoad();

    if (null != v1.user_baseinfo) {
        window.location.assign('/');
        return;
    }
    let v2 = new URL(window.location.href).searchParams.get("sso_i");
    if (typeof v2 != "string" || v2.length === 0) {
        window.location.assign('/');
        return;
    }
    let v3 = await fetch(v1.api_url + "/client/auth/sso_suud", {
        "method": "POST",
        "headers": {
            "Content-Type": "text/plain",
        },
        "body": v2
    });
    if (!v3.ok) {
        window.location.assign('/');
        return;
    }
    let v4 = await v3.text();
    if (typeof v4 != "string" || v4.length === 0) {
        window.location.assign('/');
        return;
    }
    v1.session_key = v4;
    window.location.assign('/');
};

const Suud = () => {
    const v1 = useRef(false);

    useEffect(() => {
        if (!v1.current) {
            sso_step_1();
            v1.current = true;
        }
    }, [v1]);

    return <div>
        <Helmet>
            <title>Suud Signing</title>
            <meta name="description" content="Suud Login Callback Page." />
        </Helmet>
    </div>;
};

export default Suud;