


/*
// https://github.com/intlify/vue-i18n/blob/master/packages/core-base/src/types/utils.ts#L6C1-L6C60

export type IsNever<T> = [T] extends [never] ? true : false

// https://github.com/intlify/vue-i18n/blob/master/packages/core-base/src/runtime.ts#L47

export interface GeneratedTypeConfig {}

export type GeneratedLocale =
  GeneratedTypeConfig extends Record<'locale', infer CustomLocale>
    ? CustomLocale
    : never
*/
/** @VueI18nGeneral */
//export type Locale = IsNever<GeneratedLocale> extends true ? string : GeneratedLocale

// https://github.com/nuxt-modules/i18n/blob/main/src/runtime/shared-types.ts#L274

//export interface LocaleObject<T = Locale> extends Record<string, any> {
/*
export interface LocaleObject extends Record<string, any> {
    code: string
//    name?: string
//    dir?: Directions
//    domain?: string
//    domains?: string[]
//    defaultForDomains?: string[]
//    file?: string | LocaleFile
//    files?: string[] | LocaleFile[]
//    isCatchallLocale?: boolean
    language?: string
};
*/

// https://github.com/nuxt-modules/i18n/blob/main/src/runtime/routing/utils.ts#L178
//import { isString, isSymbol, isFunction } from '@intlify/shared'

//import type { LocaleObject, Strategies, BaseUrlResolveHandler } from '../shared-types'

/*
export const inBrowser = typeof window !== 'undefined'

export function getNormalizedLocales(locales: Locale[] | LocaleObject[]): LocaleObject[] {
  locales = locales || []
  const normalized: LocaleObject[] = []
  for (const locale of locales) {
    if (isString(locale)) {
      normalized.push({ code: locale })
    } else {
      normalized.push(locale)
    }
  }
  return normalized
}

export function adjustRoutePathForTrailingSlash(
  pagePath: string,
  trailingSlash: boolean,
  isChildWithRelativePath: boolean
) {
  return pagePath.replace(/\/+$/, '') + (trailingSlash ? '/' : '') || (isChildWithRelativePath ? '' : '/')
}

export function getRouteName(routeName?: string | symbol | null) {
  if (isString(routeName)) return routeName
  if (isSymbol(routeName)) return routeName.toString()
  return '(null)'
}

export function getLocaleRouteName(
  routeName: symbol | string | null | undefined,
  locale: Locale,
  {
    defaultLocale,
    strategy,
    routesNameSeparator,
    defaultLocaleRouteNameSuffix,
    differentDomains
  }: {
    defaultLocale: string
    strategy: Strategies
    routesNameSeparator: string
    defaultLocaleRouteNameSuffix: string
    differentDomains: boolean
  }
) {
  const localizedRoutes = strategy !== 'no_prefix' || differentDomains
  let name = getRouteName(routeName) + (localizedRoutes ? routesNameSeparator + locale : '')
  if (locale === defaultLocale && strategy === 'prefix_and_default') {
    name += routesNameSeparator + defaultLocaleRouteNameSuffix
  }
  return name
};
*/
/**
 * Resolve base url
 *
 * @param baseUrl - A base url to resolve on SEO and domain. if you want to resolve with dynamically, you can spacify {@link BaseUrlResolveHandler}
 * @param context - A context to resolve base url, if you want to resolve base url with {@link BaseUrlResolveHandler}
 *
 * @returns A resolved base url
 */
/*
export function resolveBaseUrl<Context = unknown>(baseUrl: string | BaseUrlResolveHandler<Context>, context: Context) {
  if (isFunction(baseUrl)) {
    return baseUrl(context)
  }

  return baseUrl
};
*/
/**
 * The browser locale info
 *
 * @remarks
 * This type is used by {@link FindBrowserLocaleOptions#sorter | sorter} in {@link findBrowserLocale} function
 */
export interface BrowserLocale {
  /**
   * The locale code, such as BCP 47 (e.g `en-US`), or `ja`
   */
  code: string
  /**
   * The score number
   *
   * @remarks
   * The score number that is used by `sorter` of {@link FindBrowserLocaleOptions}
   */
  score: number
};

/**
 * The target locale info
 *
 * @remarks
 * This type is used by {@link BrowserLocaleMatcher} first argument
 */
//export type TargetLocale = Required<Pick<LocaleObject, 'code' | 'language'>>;
export type TargetLocale = {
    code: string;
    language: string;
};

/**
 * The browser locale matcher
 *
 * @remarks
 * This matcher is used by {@link findBrowserLocale} function
 *
 * @param locales - The target {@link LocaleObject | locale} list
 * @param browserLocales - The locale code list that is used in browser
 *
 * @returns The matched {@link BrowserLocale | locale info}
 */
export type BrowserLocaleMatcher = (locales: TargetLocale[], browserLocales: string[]) => BrowserLocale[];

/**
 * The options for {@link findBrowserLocale} function
 */
export interface FindBrowserLocaleOptions {
  matcher?: BrowserLocaleMatcher
  comparer?: (a: BrowserLocale, b: BrowserLocale) => number
};

function matchBrowserLocale(locales: TargetLocale[], browserLocales: string[]): BrowserLocale[] {
  const matchedLocales = [] as BrowserLocale[]

  // first pass: match exact locale.
  for (const [index, browserCode] of browserLocales.entries()) {
    const matchedLocale = locales.find(l => l.language.toLowerCase() === browserCode.toLowerCase())
    if (matchedLocale) {
      matchedLocales.push({ code: matchedLocale.code, score: 1 - index / browserLocales.length })
      break
    }
  }

  // second pass: match only locale code part of the browser locale (not including country).
  for (const [index, browserCode] of browserLocales.entries()) {
    const languageCode = browserCode.split('-')[0].toLowerCase()
    const matchedLocale = locales.find(l => l.language.split('-')[0].toLowerCase() === languageCode)
    if (matchedLocale) {
      // deduct a thousandth for being non-exact match.
      matchedLocales.push({ code: matchedLocale.code, score: 0.999 - index / browserLocales.length })
      break
    }
  }

  return matchedLocales
};

/**
 * The default browser locale matcher
 */
export const DefaultBrowserLocaleMatcher = matchBrowserLocale;

function compareBrowserLocale(a: BrowserLocale, b: BrowserLocale): number {
  if (a.score === b.score) {
    // if scores are equal then pick more specific (longer) code.
    return b.code.length - a.code.length
  }
  return b.score - a.score
};

/**
 * The default browser locale comparer
 */
export const DefaultBrowerLocaleComparer = compareBrowserLocale;

/**
 * Find the browser locale
 *
 * @param locales - The target {@link LocaleObject | locale} list
 * @param browserLocales - The locale code list that is used in browser
 * @param options - The options for {@link findBrowserLocale} function
 *
 * @returns The matched the locale code
 */
export function findBrowserLocale(
  locales: TargetLocale[],
  browserLocales: string[],
  { matcher = DefaultBrowserLocaleMatcher, comparer = DefaultBrowerLocaleComparer }: FindBrowserLocaleOptions = {}
): void|string {
  const normalizedLocales = []
  for (const l of locales) {
    const { code } = l
    const language = l.language || code
    normalizedLocales.push({ code, language })
  }

  // finding!
  const matchedLocales = matcher(normalizedLocales, browserLocales)

  // sort!
  if (matchedLocales.length > 1) {
    matchedLocales.sort(comparer)
  }

  return matchedLocales.length ? matchedLocales[0].code : undefined
};
/*
export function getLocalesRegex(localeCodes: string[]) {
  return new RegExp(`^/(${localeCodes.join('|')})(?:/|$)`, 'i')
};
*/
// local

export function detectDefaultLang_impl1(p1: Set<string>, p2: string = navigator.language, p3: readonly string[] = navigator.languages): void | string {
    if (p1.has(p2)) {
        return p2;
    }
    const v1 = new Set(p3);
    let v2 = v1.intersection(p1);
    if (1 === v2.size) {
        return Array.from(v2)[0];
    } else if (0 === v2.size) {
        v2 = p1;
    }
    const v3 = new Set(p2.split('-'));// browser
    const v7 = [];
    for (const v4 in v2.entries()) {
        const v5 = new Set(v4.split('-'));// file
        const v6 = v5.difference(v3);
        switch (v6.size) {
            case 0:
                return v4;// file:"en-UK",browser:"en"
            case 1:
                v7.push(v4);
                break;
        }
    }
    if (v7.length > 0) {
        return v7[0];
    }
    if (0 < v2.size) {
        return Array.from(v2)[0];// none of above
    } else {
        return undefined;
    }
};

export function detectDefaultLang(p1: Set<string>, p2: string = navigator.language, p3: readonly string[] = navigator.languages): void | string {
    if (p1.has(p2)) {
        return p2;
    }
let v1:TargetLocale[]=Array.from(p1).map((v2)=>({code:v2,language:v2}));
let v2=Array.from(p3);
let v3=findBrowserLocale(v1,v2);
console.log("nuxt_i18n_part.detectDefaultLang",p1,v3);
return v3;
};

