
export class LangFile {
    id: void | number = undefined;// server side id, disable if not allowed in system language
    language: void | string[] = undefined;// iso code, use for auto lang detect, void if disabled
    url: void | string = undefined;// lazy load
    data: void | Map<string, string> = undefined;

    constructor(p1:any){
        this.language = p1.l || undefined;
        this.id = p1.i || undefined;
        this.data = p1.d || undefined;
        this.url = p1.u || undefined;
    }

    async onEnable() {
        if (undefined === this.data && undefined !== this.url) {
            const v1 = await fetch(this.url);
            if (!v1.ok) {
                console.error("unable to fetch data by Language Loader", this.url);
                throw new Error("unable to fetch data by Language Loader: " + this.url);
            }
            const v2: void | { [key in string]: string } = await v1.json();
            if (undefined === v2) {
                console.error("empty fetch data by Language Loader", this.url);
                throw new Error("empty fetch data by Language Loader: " + this.url);
            }
            this.data = new Map();
            for (const [v3, v4] of Object.entries(v2)) {
                this.data.set(v3, v4);
            }
        }
    }

    translate(p1: string):void|string{
        if (undefined === this.data) {
            return;
        }
        return this.data.get(p1);
    }

};
