
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getOrLoad, sso_suud_start_login } from '../lib/common';
import { LangDelivrCt } from '../lib/lang_delivr_ct';
import "./navbar.css";
import * as bootstrap from "bootstrap";

const do_sign_in = sso_suud_start_login;

const do_sign_out = () => {
  getOrLoad().then((v1) => {
    return v1.signout();
  }).then(() => {
    window.location.reload();
  });
};

const do_page = (p1: string) => {
  return () => {
    window.location.assign(p1);
  }
};

const NavBar = () => {
  const login_username = useState("");
  // eslint-disable-next-line
  const t = new LangDelivrCt();
  //const [t, setT] = useState(new LangDelivrCt());
  //const t = instance_default;
  const loaded = useRef(false);
  const collapse_instance: React.MutableRefObject<bootstrap.Collapse | undefined> = useRef();
  const collapse_status = useRef(false);// area-expand

  const onNavbar3CollapseButtonCb = useCallback(() => {
    if (!(collapse_instance.current instanceof bootstrap.Collapse)) {
      collapse_instance.current = new bootstrap.Collapse('#navbarNavDropdown', {
        toggle: false, // Prevent automatic toggle
      });
    }
    if (collapse_instance.current instanceof bootstrap.Collapse) {
      if (collapse_status.current) {
        collapse_instance.current.hide();
        collapse_status.current = false;
      } else {
        collapse_instance.current.show();
        collapse_status.current = true;
      }
    }
  },[collapse_instance,collapse_status]);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      getOrLoad().then((v1) => {
        login_username[1](v1.user_name || "");
        t.initialize(v1);
      });
      if (!(collapse_instance.current instanceof bootstrap.Collapse)) {
        collapse_instance.current = new bootstrap.Collapse('#navbarNavDropdown', {
          toggle: false,
        });
      }
    }
    return () => {
      const v1=document.getElementById("navbar_sroot");
      if (loaded.current&&!(v1 instanceof HTMLElement)) {
        loaded.current = false;
        if (collapse_instance.current instanceof bootstrap.Collapse) {
          try {
            collapse_instance.current.hide();
          } catch (e) {
            console.debug(e);
          }
          try {
            collapse_instance.current.dispose();
          } catch (e) {
            console.debug(e);
          }
          collapse_instance.current = undefined;
        }
        t.finalize();
      }
    }
  }, [login_username, t]);

  t.forceLoad([
    "Not logged in",
    "Sign In",
    "Home Page",
    "ResLib Page",
    "Welcome",
    "Sign Out"
  ]);

  return (
    <nav id="navbar_sroot" className="navbar container-fluid navbar-expand-md navbar-dark zx_com_navbar">
      <img className="navbar-brand nav-item zx_com_navbar_icon za_navbar2_0_left_end" src="/favicon.svg" alt="icon" onClick={do_page("/")} />
      <button aria-expanded="false" className="navbar-toggler zx_com_navbar_right_end" onClick={onNavbar3CollapseButtonCb}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse zx_com_navbar_right_end" id="navbarNavDropdown">
        <ul className="navbar-nav ms-auto">
          {login_username[0].length === 0 ? (<>
            <li className="nav-item"><span className="nav-link">{t.keyof("Not logged in")}</span></li>
            <li className="nav-item"><button className="btn btn-outline-primary" onClick={do_sign_in}>{t.keyof("Sign In")}</button></li>
          </>) : (<>
            <li className="nav-item"><span className="nav-link" onClick={do_page("/home")}>{t.keyof("Home Page")}</span></li>
            <li className="nav-item"><span className="nav-link" onClick={do_page("/reslib")}>{t.keyof("ResLib Page")}</span></li>

            <li className="nav-item"><span className="nav-link">{t.keyof("Welcome")},&nbsp;{login_username[0]}</span></li>
            <li className="nav-item"><button className="btn btn-outline-primary" onClick={do_sign_out}>{t.keyof("Sign Out")}</button></li>
          </>)}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
