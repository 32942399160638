
import React, { useCallback, useEffect, useRef, useState } from 'react';
import NavBar from '../components/navbar';
import './ResLib.css';
import '../Background.css';
import { LangDelivrCt } from '../lib/lang_delivr_ct';
import { getOrLoad, math_log } from '../lib/common';
import * as AsyncMutex from "async-mutex";

type FileEntry = {
  _: string;
  d: boolean;
  n: string;
  p?: string;
  s: number;
  c?: string[];
};

async function rmcall_show(p1: string) {
  const v2 = await getOrLoad();
  if (null == v2) {
    return;
  }
  if (typeof v2.session_key != "string" || v2.session_key.length <= 0) {
    return;
  }
  const v3 = await fetch(v2.api_url + "/client/reslib/show", {
    method: "POST",
    body: JSON.stringify({
      k: v2.session_key || "",
      f: p1
    })
  });
  if (!v3.ok) {
    return;
  }
  return Object.seal(await v3.json());
};

async function rmcall_rootdirid() {
  const v2 = await getOrLoad();
  if (null == v2) {
    return;
  }
  if (typeof v2.session_key != "string" || v2.session_key.length <= 0) {
    return;
  }
  const v3 = await fetch(v2.api_url + "/client/reslib/rootdirid", {
    method: "POST",
    body: v2.session_key || ""
  });
  if (!v3.ok) {
    return;
  }
  return await v3.text();
};

async function rmcall_download(p1: string, p2: number, p3: number) {
  const v1 = await getOrLoad();
  if (typeof v1.session_key != "string" || v1.session_key.length === 0) {
    return undefined;
  }
  const v2 = await fetch(v1.api_url + "/client/reslib/download", {
    method: "POST",
    body: JSON.stringify({
      o: p2, l: p3, f: p1,
      k: v1.session_key
    })
  });
  if (!v2.ok) {
    return undefined;
  }
  return await v2.blob();
};

class DlTask {

  constructor(p1: FileEntry, p2: number = 0, p3: number = 1024 * 1024) {
    if (p1.d || 0 === p1.s) {
      throw new Error("download dir is not support\n" + JSON.stringify(p1));
    }
    this.target = p1;
    this.chunkSize = p3;
    {
      let v1 = Math.ceil((p1.s) / p3);
      let v2 = new Array(v1);
      v2.fill(undefined);
      this.chunks = Object.seal(v2);
    }
    let v3 = p2;
    if (v3 < 1) {
      v3 = math_log(2, this.chunks.length);
      v3 = Math.floor(v3);
      if (v3 < 1) {
        v3 = 1;
      }
      if (v3 > 4) {
        v3 = 4;
      }
    }
    {
      let v2 = new Array(Math.min(v3, this.chunks.length));
      v2.fill(undefined);
      this.workers = Object.seal(v2);
    }
    this.tasks = [];
    this.status = 1;
    this.lock = new AsyncMutex.Mutex();
  }

  async #DlCommonChunk(p1: number) {
    const v0 = this;
    let v1 = true;// is this worker active
    while (v1) {
      let v2 = await v0.lock.runExclusive(async () => {
        if (v0.status !== 2) {
          return -2;
        }
        if (v0.tasks.length === 0) {
          if (v0.chunks.filter(v5 => !(v5 instanceof Blob)).length === 0) {
            v0.status = 4;
            return -2;
          }
          return -1;
        }
        let v3 = v0.tasks.shift();
        if (typeof v3 != "number" || !Number.isSafeInteger(v3)) {
          return -1;
        }
        return v3;// got a task
      });
      if (-2 === v2) {
        break;
      }
      if (-1 === v2) {
        await new Promise((v6) => setTimeout(v6, 50));
        continue;
      }
      let v4 = undefined;
      try {
        let v8 = v0.chunkSize;
        if (v0.chunks.length - 1 === v2) {
          v8 = v0.target.s % v0.chunkSize;
          if (0 === v8) {
            v8 = v0.chunkSize;
          }
        }
        v4 = await rmcall_download(v0.target._, v0.chunkSize * v2, v8);
      } catch (v5) {
        console.error("download failed:", v0.target, v2, v5);
      }
      if (v4 instanceof Blob) {
        v1 = await v0.lock.runExclusive(async () => {
          if (v0.status !== 2) {
            return false;
          }
          v0.chunks[v2] = v4;
          await v0.#emitUpdate();
          return true;
        });
        if (!v1) {
          break;
        }
      } else {
        v1 = await v0.lock.runExclusive(async () => {
          if (v0.status !== 2) {
            return false;
          }
          v0.tasks.push(v2);
          return true;
        });
        if (!v1) {
          break;
        }
        await new Promise((v6) => setTimeout(v6, 1000));
      }
    }

    v1 = true;
    while (v1) {
      v1 = await v0.lock.runExclusive(async () => {
        if (4 !== v0.status) {
          return false;
        }
        {
          const v2 = v0.chunks.filter(v5 => !(v5 instanceof Blob)).length;
          if (0 !== v2) {
            return true;
          }
        }
        {
          const v2 = v0.chunks.filter(v5 => v5 instanceof Blob);
          if (v2.length !== v0.chunks.length) {
            return true;
          }
          v0.output = URL.createObjectURL(new Blob(v2));
        }
        v0.status = 5;
        await v0.#emitUpdate();
        return true;
      });
      if (v1) {
        await new Promise((v6) => setTimeout(v6, 50));
      }
    }
  }

  start() {
    const v0 = this;
    return v0.lock.runExclusive(async () => {
      if (1 !== v0.status) {
        return;
      }
      {
        const v2 = [];
        for (let v1 = 0; v1 < v0.chunks.length; ++v1) {
          if (v0.chunks[v1] instanceof Blob) { } else {
            v2.push(v1);
          }
        }
        v0.tasks = v2;
      }
      v0.status = 2;
      if (v0.workers.length > 0) {
        for (let v1 = 0; v1 < v0.workers.length; ++v1) {
          v0.workers[v1] = v0.#DlCommonChunk(v1);
        }
      }
    });
  }

  cancel() {
    const v0 = this;
    return v0.lock.runExclusive(async () => {
      if (3 > v0.status || 4 === v0.status) {
        v0.status = 3;
      }
    });
  }

  wait() {
    return Promise.all(this.workers);
  }

  async destroy() {
    await this.cancel();
    await this.wait();
    const v0 = this;
    await v0.lock.runExclusive(async () => {
      v0.status = 3;
      if (v0.output.length > 0) {
        URL.revokeObjectURL(v0.output);
      }
      v0.tasks = [];
      v0.workers = [];
      v0.output = "";
    });
  }

  /* @lock_guard(this.lock) */
  async #emitUpdate() {
    if (typeof this.onUpdate !== "function") {
      return;
    }
    const v1 = Date.now();
    if (
      (3 === this.status || 5 === this.status) ||
      (this.lastPost + 500 < v1)
    ) {
      this.lastPost = v1;
      await this.onUpdate(this);
    }
  }

  readonly chunkSize: number;
  readonly target: FileEntry;
  tasks: number[];
  readonly chunks: (void | Blob)[];
  /* 1 created, 2 running, 3 cancelled, 4 dlcomplete, 5 finished */
  status: number = 0;
  readonly lock: AsyncMutex.Mutex;
  workers: (void | Promise<void>)[];
  output: string = "";
  lastPost: number = 0;
  onUpdate: ((arg0: DlTask) => Promise<void>) = async () => { };
};

function SizeToReadable(p1: number) {
  let v4 = p1;
  const v7 = ["B", "KB", "MB", "GB", "TB"];
  let v8 = 0;
  while (v4 >= 1024 && v8 < v7.length - 1) {
    v4 /= 1024;
    v8++;
  }
  return (v4.toFixed(3) + v7[v8]);
};

const ResLib = () => {
  // eslint-disable-next-line
  const t = new LangDelivrCt();
  const [getRemainingStr, setRemainingStr] = useState("");
  const RemainingInt = useRef(0);
  const [getFileList, setFileList] = useState<FileEntry[]>([]);
  const FilePath = useRef<FileEntry[]>([]);
  const [FilePathReceive, FilePathNotify] = useState<FileEntry[]>([]);
  const FileCache: React.MutableRefObject<Map<string, FileEntry>> = useRef(new Map());
  const Loaded = useRef(false);
  const DlTaskList = useRef<DlTask[]>([]);
  const [DlTaskUpdateR, DlTaskUpdateN] = useState<DlTask[]>([]);

  const DlTaskUpdateC = useCallback(() => {
    const v1 = DlTaskList.current;
    const v2 = [];
    for (const v3 of v1) {
      if (3 === v3.status) {
        continue;
      }
      v2.push(v3);
    }
    DlTaskUpdateN(Object.seal(v2));
  }, [DlTaskUpdateN, DlTaskList]);

  const LoadFileInfo = useCallback<(a: string) => Promise<void | FileEntry>>(async (p1: string) => {
    const v2 = p1.toUpperCase();
    if (FileCache.current.has(v2)) {
      return FileCache.current.get(v2);
    }
    const v1 = await rmcall_show(v2);
    if (typeof v1 == "object") {
      FileCache.current.set(v2, v1);
      return v1;
    }
    return undefined;
  }, [FileCache]);

  const updateRemaining = useCallback(async () => {
    const v2 = await getOrLoad();
    if (null == v2) {
      return;
    }
    if (typeof v2.session_key != "string" || v2.session_key.length <= 0) {
      return;
    }
    const v3 = await fetch(v2.api_url + "/client/reslib/remaining", {
      method: "POST",
      body: v2.session_key || ""
    });
    if (!v3.ok) {
      return;
    }
    let v4 = Number.parseInt(await v3.text());
    if (typeof v4 != "number") {
      return;
    }
    RemainingInt.current = v4;
    setRemainingStr(SizeToReadable(v4));
  }, [setRemainingStr, RemainingInt]);

  const TurnToFolder = useCallback(async () => {
    if (FilePath.current.length === 0) {
      return;
    }
    FilePathNotify(FilePath.current);
    const v1 = await LoadFileInfo(FilePath.current[FilePath.current.length - 1]._);
    if (typeof v1 !== "object") {
      return;
    }
    if (!v1.d) {
      return;
    }
    if (!Array.isArray(v1.c)) {
      setFileList([]);
      return;
    }
    let v3: FileEntry[] = [];
    for (const v2 of v1.c) {
      const v4 = await LoadFileInfo(v2);
      if (typeof v4 != "object") {
        continue;
      }
      v3.push(v4);
    }
    setFileList(v3);
  }, [FilePath, setFileList, LoadFileInfo, FilePathNotify]);

  const TurnToParent = useCallback(async () => {
    if (FilePath.current.length <= 1) {
      console.log("already on root");
      return;
    }
    FilePath.current.pop();
    await TurnToFolder();
  }, [TurnToFolder, FilePath]);

  const TurnToChild = useCallback(async (p1: string) => {
    const v1 = await LoadFileInfo(p1);
    if (typeof v1 != "object") {
      console.log("no such child");
      return;
    }
    FilePath.current.push(v1);
    await TurnToFolder();
  }, [LoadFileInfo, TurnToFolder]);

  const DlAdd = useCallback(async (p1: string) => {
    await updateRemaining();
    const v1 = await LoadFileInfo(p1);
    if (null == v1) {
      return;
    }
    if (RemainingInt.current < v1.s) {
      return;
    }
    const v2 = new DlTask(v1);
    v2.onUpdate = async () => {
      DlTaskUpdateC();
    };
    DlTaskList.current.push(v2);
    v2.start();
    DlTaskUpdateC();
  }, [LoadFileInfo, DlTaskList, DlTaskUpdateC,updateRemaining]);

  const DlDel = useCallback(async (p1: DlTask) => {
    await p1.destroy();
    let v4 = DlTaskList.current.filter(v3 => v3 !== p1);
    DlTaskList.current = v4;
    DlTaskUpdateC();
  }, [DlTaskList, DlTaskUpdateC]);

  const DrawFolder = useCallback((p1: FileEntry[], p2: FileEntry[]) => {
    if (null == p1) {
      return [];
    }
    let v1 = [];
    if (p1.length > 1) {
      let v2 = p1[p1.length - 2];
      v1.push(<tr className="fs-4 text-light"
        onClick={() => {
          TurnToParent();
        }}>
        <th scope="row">
          <i className="bi bi-reply-all"></i>
        </th>
        <td>..</td>
        <td>{v2.s}</td>
      </tr>);
    }
    for (let v2 of p2) {
      v1.push(<tr
        onClick={() => {
          if (v2.d) {
            TurnToChild(v2._);
          } else {
            DlAdd(v2._);
          }
        }}>
        <th scope="row">{v2.d ?
          <i className="bi bi-folder2"></i> :
          <i className="bi bi-file-earmark"></i>
        }</th>
        <td>{v2.n}</td>
        <td>{v2.d ? v2.s : SizeToReadable(v2.s)}</td>
      </tr>);
    }
    return v1;
  }, [TurnToParent, TurnToChild, DlAdd]);

  const DrawPath = useCallback((p1: FileEntry[]) => {
    const v1 = [];
    let v2 = 0;
    while (v2 < p1.length) {
      const v3 = p1.slice(0, 1 + v2);
      const v4 = p1[v2].n;
      v1.push(<span
        onClick={() => {
          FilePath.current = v3;
          TurnToFolder();
        }}>{v4}</span>);
      v1.push(<i className="bi bi-chevron-right"></i>);
      ++v2;
    }
    v1.pop();
    return v1;
  }, [TurnToFolder]);

  const DrawDlTask = useCallback((p1: DlTask[]) => {
    let v1 = [];
    for (let v2 of p1) {
      switch (v2.status) {
        case 0:
        case 1:
          v1.push(<div className="w-100">
            <p>
              <span>{v2.target.n}</span>
              <button type="button" className="btn btn-outline-danger" onClick={() => {
                DlDel(v2);
              }}><i className="bi bi-x-circle"></i></button>
            </p>
            <p><i className="bi bi-gear"></i></p>
          </div>);
          break;
        case 2:
          {
            const v5 = v2.tasks.length;
            const v6 = v2.chunks.length;
            v1.push(<div className="w-100">
              <p>
                <span>{v2.target.n}</span>
                <button type="button" className="btn btn-outline-danger" onClick={() => {
                  DlDel(v2);
                }}><i className="bi bi-x-circle"></i></button>
              </p>
              <p>
                <span>{SizeToReadable((v6 - v5) * v2.chunkSize)}</span>
                <i className="bi bi-slash-lg"></i>
                <span>{SizeToReadable(v5 * v2.chunkSize)}</span>
                <i className="bi bi-play"></i>
                <span>{((v6 - v5) / v6 * 100).toFixed(1)}%</span>
              </p>
              <div className="progress-stacked" style={{ height: "0.2rem" }}>
                <div className="progress" role="progressbar" aria-label="download progress"
                  aria-valuenow={v6 - v5} aria-valuemin={0} aria-valuemax={v6}
                  style={{ width: (((v6 - v5) / v6 * 100) + '%') }}>
                  <div className="progress-bar"></div>
                </div>
              </div>
            </div>);
          }
          break;
        case 4:
          v1.push(<div className="w-100">
            <p>
              <span>{v2.target.n}</span>
              <button type="button" className="btn btn-outline-danger" onClick={() => {
                DlDel(v2);
              }}><i className="bi bi-x-circle"></i></button>
            </p>
            <p><i className="bi bi-gear-wide-connected"></i></p>
          </div>);
          break;
        case 5:
          v1.push(<div className="w-100">
            <p>
              <span>{v2.target.n}</span>
              <button type="button" className="btn btn-outline-danger" onClick={() => {
                DlDel(v2);
              }}><i className="bi bi-x-circle"></i></button>
            </p>
            <p>
              <a type="button" className="btn btn-outline-success"
                href={v2.output} download={v2.target.n}
              ><i className="bi bi-save"></i></a>
            </p>
          </div>);
          break;
        case 3:
        default:
          break;
      }
    }
    return v1;
  }, [DlDel]);

  t.forceLoad([
    "ResLib Page Remaining"
  ]);

  useEffect(() => {
    const v2 = async () => {
      const v1 = await getOrLoad();
      if (null == v1.user_baseinfo) {
        window.location.replace("/");
      }

      await updateRemaining();
      const v2 = await rmcall_rootdirid();
      if (typeof v2 != "string" || v2.length === 0) {
        console.warn("unable to receive root dir info 1");
        return;
      }
      const v3 = await LoadFileInfo(v2);
      if (typeof v3 != "object") {
        console.warn("unable to receive root dir info 2");
        return;
      }
      FilePath.current.push(v3);
      await TurnToFolder();

      t.initialize(v1);
    };
    if (Loaded.current) { } else {
      Loaded.current = true;
      v2();
    }
    return () => {
      const v1 = document.getElementById("reslib_sroot");
      if (Loaded.current&&!(v1 instanceof HTMLDivElement)) {
        Loaded.current = false;
        t.finalize();
      }
    }
  }, [t, updateRemaining, TurnToFolder, LoadFileInfo, Loaded]);

  return (
    <div className="bg-image">
      <NavBar />

      <div id="reslib_sroot" className="row d-flex p-0 m-0 g-0" style={{ height: '100vh' }}>

        <div className="col-lg-4 fs-4 text-light overflow-auto" style={{ maxHeight: '100%', overflowY: 'auto' }}>
          <div>
            <span>{t.keyof("ResLib Page Remaining")}</span>
            <span>: </span>
            <span>{getRemainingStr}</span>
          </div>
          <div>
            {DrawPath(FilePathReceive)}
          </div>
          <div>
            {DrawDlTask(DlTaskUpdateR)}
          </div>
          <div style={{ height: "6rem" }}>

          </div>
        </div>

        <div className="col-lg-8 overflow-auto" style={{ maxHeight: '100%', overflowY: 'auto' }}>
          <table className="table table-borderless m-0 p-0 g-0 caption-top fs-4 text-light">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{t.keyof("FileName")}</th>
                <th scope="col">{t.keyof("FileSize")}</th>
              </tr>
            </thead>
            <tbody>
              {DrawFolder(FilePathReceive, getFileList)}
              <tr className="fs-4" style={{ color: "transparent", height: "6rem" }}>
                <th scope="row"></th>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
};

export default ResLib;