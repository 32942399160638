/*
 * Author: Steve Shire
 * This resume webpage was independently created by Steve Shire and is intended for use in job applications.
 * All rights reserved.
 */
import { Helmet } from 'react-helmet';

import "./About.css";
import { useCallback, useEffect, useRef, useState } from 'react';
import { getOrLoad } from '../lib/common';
import * as bootstrap from "bootstrap";

function shouldNavbar(width: number, height: number) {
  if (width < 700) {
    return true;
  }
  if (height < 600) {
    return true;
  }
  const ratio = width / height;
  if (height >= 600 && ratio > 1) {
    return false;
  }
  return ratio > 2 || ratio < 0.875;
};

async function send_contact_form(p1: {
  k: void | string;
  fn: string;
  ln: string;
  e: string;
  w: number;
  m: string;
}): Promise<{ s: number; e: string[]; }> {
  const v1 = await getOrLoad();
  let v2: {
    k: void | string;
    fn: string;
    ln: string;
    e: string;
    w: number;
    m: string;
  } = p1;
  v2.k = v1.session_key;
  const v3 = await fetch(v1.api_url + "/client/about/contact_submit", {
    "method": "POST",
    "body": JSON.stringify(v2)
  });
  if (!v3.ok) {
    return { s: 2, e: [] };
  }
  return await v3.json();
};
// https://getbootstrap.com/docs/5.3/components/modal/#live-demo

const About = () => {
  const [navbar_getMode, navbar_setMode] = useState(true);// default: true !important; false => menu, true => navbar
  const [section7_getContact, section7_setContact] = useState(true);// true => show contact from, false => show contact sent success
  const navbar_element = useRef(null);
  const section0_element = useRef(null);
  const navbar_scrollSpy: React.MutableRefObject<bootstrap.ScrollSpy | undefined> = useRef();
  const navbar_collapse: React.MutableRefObject<bootstrap.Collapse | undefined> = useRef();
  const navbar_collapse_status = useRef(false);// area-expand
  const section2_modal: React.MutableRefObject<bootstrap.Modal | undefined> = useRef();
  const section2_carousel: React.MutableRefObject<bootstrap.Carousel | undefined> = useRef();

  const onWindowResize = useCallback(async () => {
    //console.log("onWindowResize");
    if (section2_modal.current instanceof bootstrap.Modal) {
      //console.log("onWindowResize + section2_modal", section2_modal.current);
      section2_modal.current.handleUpdate();
    }
    const v3 = shouldNavbar(window.innerWidth, window.innerHeight);
    const v4 = v3 !== navbar_getMode;

    if (v4) {
      //console.log(v3, navbar_getMode, v3 === navbar_getMode);
      navbar_setMode(v3);
      //setTimeout(onWindowResize, 20);
    }

    if (v4) {
      if (v3) {
        //console.log("onWindowResize + navbar_collapse create");
        if (!(navbar_collapse.current instanceof bootstrap.Collapse)) {
          navbar_collapse.current = new bootstrap.Collapse('#navbar3-listener', {
            toggle: false,
          });
        }
      } else {
        //console.log("onWindowResize + navbar_collapse destroy");
        if (navbar_collapse.current instanceof bootstrap.Collapse) {
          try {
            navbar_collapse.current.hide();
          } catch (e) {
            console.debug(e);
          }
          try {
            navbar_collapse.current.dispose();
          } catch (e) {
            console.debug(e);
          }
          navbar_collapse.current = undefined;
        }
      }
    }

    if (v3) {
      if (null != navbar_element.current && null != section0_element.current) {
        section0_element.current.style.marginTop = ('' + navbar_element.current.offsetHeight) + "px";
      }
    } else {
      if (null != section0_element.current) {
        section0_element.current.style.marginTop = "0px";
      }
    }

    if (undefined !== navbar_scrollSpy.current) {
      navbar_scrollSpy.current.refresh();
    }
  }, [navbar_collapse, navbar_scrollSpy, navbar_getMode, navbar_setMode, section0_element, navbar_element]);

  const onNavbar3CollapseButtonCb = useCallback(() => {
    if (!(navbar_collapse.current instanceof bootstrap.Collapse)) {
      navbar_collapse.current = new bootstrap.Collapse('#navbar3-listener', {
        toggle: false, // Prevent automatic toggle
      });
    }
    if (navbar_getMode && navbar_collapse.current instanceof bootstrap.Collapse) {
      if (navbar_collapse_status.current) {
        navbar_collapse.current.hide();
        navbar_collapse_status.current = false;
      } else {
        navbar_collapse.current.show();
        navbar_collapse_status.current = true;
      }
    } else {
      // not allowed here
    }
  },[navbar_collapse,navbar_collapse_status,navbar_getMode]);

  const submitContact = useCallback(() => {
    const v2: null | HTMLElement = document.getElementById('s-7-f_submit');
    if (v2 instanceof HTMLButtonElement) {
      v2.disabled = true;
    }
    send_contact_form({
      k: undefined,
      fn: document.getElementById('s-7-f_fn')?.value,
      ln: document.getElementById('s-7-f_ln')?.value,
      e: document.getElementById('s-7-f_e')?.value,
      w: parseInt(document.getElementById('s-7-f_w')?.value),
      m: document.getElementById('s-7-f_m')?.value
    }).then((v1) => {
      switch (v1.s) {
        case 0:
          section7_setContact(false);
          break;
        case 1:
          {
            const v3: Map<string, string> = new Map([
              ["fn", 's-7-f_fn'],
              ["ln", 's-7-f_ln'],
              ["e", 's-7-f_e'],
              ["w", 's-7-f_w'],
              ["m", 's-7-f_m']
            ]);
            for (const [v4, v5] of v3) {
              const v6 = document.getElementById(v5);
              if (!(v6 instanceof HTMLElement)) {
                continue;
              }
              if (v1.e.includes(v4)) {
                v6.classList.add('is-invalid');
                v6.classList.remove('is-valid');
              } else {
                v6.classList.remove('is-invalid');
                v6.classList.add('is-valid');
              }
            }
          }
          break;
        case 2:
          console.error("send_contact_form network error");
          break;
        default:
          console.error("send_contact_form unknown error", v1.s);
          break;
      }
      if (v2 instanceof HTMLButtonElement) {
        v2.disabled = false;
      }
    });
  },[section7_setContact]);

const s_2_m_0_open_1=useCallback((p2: void | string) => {
  if (typeof p2 == "string" && p2.length > 0) {
    if (section2_carousel.current instanceof bootstrap.Carousel) {
      section2_carousel.current.dispose();
    }
    section2_carousel.current = new bootstrap.Carousel(p2, {
      touch: true,
      ride: "carousel",
      interval: 5000
    });
  }
},[section2_carousel]);

  const s_2_m_0_open = useCallback((p1: string, p2: void | string) => {
    //console.log("s_2_m_0_open");
    if (section2_modal.current instanceof bootstrap.Modal) {
      section2_modal.current.dispose();// close current
    }
    section2_modal.current = new bootstrap.Modal(p1, {
      backdrop: true,
      focus: true
    });
    section2_modal.current.show();
    try {
      s_2_m_0_open_1(p2);
    } catch (e1) {
      //console.log(e1);
      setTimeout(() => {
        try {
          s_2_m_0_open_1(p2);
        } catch (e2) {
          //console.log(e2);
        }
      }, 50);
    }
  },[section2_modal,s_2_m_0_open_1]);

  const s_2_m_0_close = useCallback(() => {
    //console.log("s_2_m_0_close");
    if (section2_carousel.current instanceof bootstrap.Carousel) {
      //section2_carousel.current.to(0);
      section2_carousel.current.dispose();
      section2_carousel.current = undefined;
    }
    if (section2_modal.current instanceof bootstrap.Modal) {
      //section2_modal.current.hide();
      section2_modal.current.dispose();
      section2_modal.current = undefined;
    }
  }, [section2_modal]);

  function s_2_m_1_open() {
    s_2_m_0_open('#s-2-m-1', "#s-2-m-1-s-1");
  };

  function s_2_m_2_open() {
    s_2_m_0_open('#s-2-m-2', "#s-2-m-2-s-1");
  };

  function s_2_m_3_open() {
    s_2_m_0_open('#s-2-m-3', "#s-2-m-3-s-1");
  };

  useEffect(() => {
    if (undefined !== navbar_scrollSpy.current) {
      navbar_scrollSpy.current.dispose();
    }
    navbar_scrollSpy.current = new bootstrap.ScrollSpy(document.body, {
      target: '#navbar3-listener',
    });

    window.addEventListener('resize', onWindowResize);
    document.getElementById('s-2-m-1')?.addEventListener('hidden.bs.modal', s_2_m_0_close);
    document.getElementById('s-2-m-2')?.addEventListener('hidden.bs.modal', s_2_m_0_close);
    document.getElementById('s-2-m-3')?.addEventListener('hidden.bs.modal', s_2_m_0_close);

    onWindowResize();
    return () => {
      if (null !== document.getElementById("s")) {
        //console.log("wtf??");
        return;
      }
      //console.log("useEffect.onClose");

      window.removeEventListener('resize', onWindowResize);
      document.getElementById('s-2-m-1')?.removeEventListener('hidden.bs.modal', s_2_m_0_close);
      document.getElementById('s-2-m-2')?.removeEventListener('hidden.bs.modal', s_2_m_0_close);
      document.getElementById('s-2-m-3')?.removeEventListener('hidden.bs.modal', s_2_m_0_close);

      if (navbar_scrollSpy.current instanceof bootstrap.ScrollSpy) {
        navbar_scrollSpy.current.dispose();
        navbar_scrollSpy.current = undefined;
      }
      if (navbar_collapse.current instanceof bootstrap.Collapse) {
        navbar_collapse.current.dispose();
        navbar_collapse.current = undefined;
      }
      if (section2_carousel.current instanceof bootstrap.Carousel) {
        section2_carousel.current.dispose();
        section2_carousel.current = undefined;
      }
      if (section2_modal.current instanceof bootstrap.Modal) {
        section2_modal.current.dispose();
        section2_modal.current = undefined;
      }
    };
  }, [onWindowResize, s_2_m_0_close]);
  return (<>
    <Helmet>
      <link rel="icon" href="/assets/about/avatar_self.svg" />
      <link rel="apple-touch-icon" href="/assets/about/avatar_self.svg" />
      <meta property="og:image" content="/assets/about/avatar_self.svg" />
      <meta property="twitter:image" content="/assets/about/avatar_self.svg" />

      <title>Steve Shire Resume</title>
      <meta property="og:title" content="title" />

      <meta name="description" content="description" />
      <meta property="og:description" content="description" />

      <meta name="theme-color" content="#7f7f7f" />

      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>

    <div className="row g-0">

      <nav ref={navbar_element} className={"navbar-dark " + (navbar_getMode ?
        "navbar container-fluid navbar-expand-lg za_navbar2_0 fixed-top" :
        "za_navbar1_0 justify-content-center align-items-center d-flex flex-column")}>
        <a href="#s" className={"navbar-brand " + (navbar_getMode ?
          "za_navbar2_0_left_end" :
          "justify-content-center align-items-center d-flex flex-column d-none")}>
          <img src="/assets/about/avatar_self.svg" alt="Avatar" className={(navbar_getMode ?
            "za_navbar2_img1_1" :
            "za_navbar1_img1_1 d-none")
            + " img-fluid rounded-circle"} />
        </a>
        <button id="navbar3-collapse-button" onClick={onNavbar3CollapseButtonCb}
          className={"navbar-toggler " + (navbar_getMode ? "za_navbar2_0_right_end" : "d-none")}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <nav className={(navbar_getMode ?
          "collapse navbar-collapse za_navbar2_0_right_end" :
          "flex-column")} id="navbar3-listener">
          <nav className={"nav nav-pills " + (navbar_getMode ?
            "navbar-nav ms-auto" :
            "flex-column justify-content-center align-items-center")}>
            <a className={(navbar_getMode ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-1">Steve Shire</a>
            <a className={(navbar_getMode ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-2">Projects &amp; Portfolio</a>
            <a className={(navbar_getMode ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-3">About</a>
            <a className={(navbar_getMode ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-4">Skills</a>
            <a className={(navbar_getMode ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-5">Interests</a>
            <a className={(navbar_getMode ? "za_navbar3_pills_spacing" : "") + " nav-item nav-link link-light za_navbar3_nav-item"} href="#s-7">Contacts</a>
          </nav>
        </nav>
      </nav>

      <div className={navbar_getMode ? "" : "za_sbase_navleft"} ref={section0_element}>
        <div data-bs-spy="scroll" data-bs-target="#navbar3-listener" data-bs-smooth-scroll="true" tabIndex={0} id="s">

          <div id="s-1" className="container za_section justify-content-center align-items-center d-flex flex-column za_item_background-color_1">
            <img src="/assets/about/avatar_self.svg" alt="Avatar" className="s-1-i-avatar img-fluid" />
            <h1 className="display-5 za_font_art_5 fst-italic fw-bold za_item_color_darkslateblue">Steve Shire</h1>
            <div className="s-1-divider-l0">
              <div className="s-1-divider-l1p1"></div>
              <div className="s-1-divider-l1p2">
                <i className="bi bi-person-fill"></i>
              </div>
              <div className="s-1-divider-l1p3"></div>
            </div>
            <p className="za_item_color_darkslateblue za_font-size_1-25rem">Software Engineer | Full-Stack Web Developer</p>
            <p className="za_item_color_darkslateblue za_font-size_1-25rem za_font-weight_800 za_font-size_1-25rem">Creator of the <a target="_blank" rel="noreferrer" className="za_item_color_darkslateblue" href="https://suud.net/">SUUD Project</a>.</p>
          </div>

          <div id="s-2" className="container za_section za_item_background-color_6">
            <p className="text-center fs-1 za_font_art_title" id="s-2-main-header">Projects &amp; Portfolio</p>

            <div className="w-100 row d-flex flex-column align-items-center justify-content-center">

              <div className="col-md-6 px-0 s-2-proj-prev-l0 za_item_margin_all_1rem align-items-center justify-content-center" onClick={s_2_m_1_open}>
                <div className="s-2-proj-prev-lc0"></div>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l1 za_font_art_1">
                  <span className="fs-2">SUUD Project</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l3 za_font_art_2">
                  <span className="za_font-size_1rem">Mar 2024 - Sep 2024</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l6 za_font_art_3 za_item_padding_width_1rem za_item_border_lightblack">
                  <span className="fs-4">Learn More<img src="/assets/external/icon-icons.com/click_icon_155646.svg" alt=""></img></span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l8 za_font_art_4">
                  <span className="fs-6">My 1st project</span>
                </p>
                <div className="s-2-proj-prev-lbsc0 za_item_background-color_purple s-2-proj-prev-lp0"></div>
              </div>

              <div className="col-md-6 px-0 s-2-proj-prev-l0 za_item_margin_all_1rem align-items-center justify-content-center" onClick={s_2_m_2_open}>
                <div className="s-2-proj-prev-lc0"></div>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l1 za_font_art_1">
                  <span className="fs-2">HW &amp; YZ</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l3 za_font_art_2">
                  <span className="za_font-size_1rem">Aug 2024 - Sep 2024</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l6 za_font_art_3 za_item_padding_width_1rem za_item_border_lightblack">
                  <span className="fs-4">Learn More<img src="/assets/external/icon-icons.com/click_icon_155646.svg" alt=""></img></span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l8 za_font_art_4">
                  <span className="fs-6">My 2nd project</span>
                </p>
                <div className="s-2-proj-prev-lbsc0 za_item_background-color_gold s-2-proj-prev-lp0"></div>
              </div>

              <div className="col-md-6 px-0 s-2-proj-prev-l0 za_item_margin_all_1rem align-items-center justify-content-center" onClick={s_2_m_3_open}>
                <div className="s-2-proj-prev-lc0"></div>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l1 za_font_art_1">
                  <span className="fs-2">Improved CyNER</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l3 za_font_art_2">
                  <span className="za_font-size_1rem">Aug 2023 - Dec 2023</span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l6 za_font_art_3 za_item_padding_width_1rem za_item_border_lightblack">
                  <span className="fs-4">Learn More<img src="/assets/external/icon-icons.com/click_icon_155646.svg" alt=""></img></span>
                </p>
                <p className="s-2-proj-prev-lw0 s-2-proj-prev-lw1_l8 za_font_art_4">
                  <span className="fs-6">Yet another project</span>
                </p>
                <div className="s-2-proj-prev-lbsc0 za_item_background-color_darkcyan s-2-proj-prev-lp0"></div>
              </div>

            </div>
          </div>

          <div id="s-3" className="container za_section za_item_background-color_2 za_part_color_whitesmoke">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
                <p className="text-center fs-1 za_font_art_title">About</p>
              </div>
              <div className="s-3-divider-l0">
                <div className="s-3-divider-l1p1"></div>
                <div className="s-3-divider-l1p2">
                  <i className="bi bi-lightbulb-fill"></i>
                </div>
                <div className="s-3-divider-l1p3"></div>
              </div>
            </div>

            <div className="row pb-4 px-4">
              <div className="col-lg-6">
                <p className="mb-2 mt-2">
                  <strong>Full-Stack Web developer</strong> skilled in creating secure and stable websites using the latest technologies.
                  Proficient in <strong>TypeScript, Java, C#, C++</strong>, and frameworks like <strong>React, NodeJS and .NET</strong>.
                  Quick to adapt, fitting into any framework within 1 month.
                </p>
                <p className="mt-2 mb-0"><strong>About me</strong></p>
                <ul className="mt-0 mb-2">
                  <li>&#x1F6EB;Start coding since 2013 (The first time to learn JavaScript &amp; PHP).</li>
                  <li>&#x1F393;Graduated from the <a className="text-white" target="_blank" rel="noreferrer" href="https://www.ecu.edu.au/">Edith Cowan University</a> Master of Cyber Security.</li>
                  <li>&#x1F4D6;Recently learned ReactJS and ASP.NET.</li>
                  <li>&#x1F3AF;Target to become a full-stack developer</li>
                </ul>
                <p className="mt-2 mb-0">Once the internship is confirmed by employer, I can start learning any required TechStack, usually 1 month for each.</p>
                <p className="mt-0 mb-2">Once the job is confirmed by employer, I can move to the city where company locates.</p>
              </div>
              <div className="col-lg-6">
                <div className="mt-2 mb-4">
                  <p className="mt-0 mb-0"><strong>Full Stack Developer</strong></p>
                  <p className="mt-0 mb-0">HW & YZ LLP</p>
                  <p className="mt-0 mb-0 fst-italic">Mar 2024 - Sep 2024 · 7 mos</p>
                  <p className="mt-0 mb-2 fst-italic">London Area, United Kingdom · Remote</p>
                  <p className="mt-2 mb-2">Ensured high levels of security and performance across both front-end and back-end layers, utilizing modern web technologies.</p>
                  <p className="mt-2 mb-0">Related Projects:</p>
                  <ul className="mt-0 mb-2">
                    <li>SUUD Project</li>
                    <li>HWYZ Official Website</li>
                  </ul>
                </div>
                <div className="mt-4 mb-0">
                  <p className="mt-0 mb-0"><strong>Back End Engineer</strong></p>
                  <p className="mt-0 mb-0">Shanghai Nebulo Technology Co., Ltd</p>
                  <p className="mt-0 mb-0 fst-italic">Aug 2020 - Jan 2021 · 6 mos</p>
                  <p className="mt-0 mb-2 fst-italic">Shanghai, China · Hybrid</p>
                  <p className="mt-2 mb-2">Led secure and scalable web system development, disaster recovery, and debugging across multiple systems to ensure service continuity and data integrity.</p>
                  <p className="mt-2 mb-0">Achievements: Recovery 6 blog websites from disaster.</p>
                </div>
              </div>
            </div>

            <div className="text-center">
              <a className="btn btn-outline-light" target="_blank" rel="noreferrer"
                href="https://github.com/314159265358x">
                <i className="bi bi-github"></i>
                <span> My GitHub Profile</span>
              </a>
            </div>
          </div>

          <div id="s-4" className="container za_section za_item_background-color_5">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
                <p className="text-center fs-1 za_font_art_title">Skills</p>
              </div>
              <div className="s-4-divider-l0">
                <div className="s-4-divider-l1p1"></div>
                <div className="s-4-divider-l1p2">
                  <i className="bi bi-book"></i>
                </div>
                <div className="s-4-divider-l1p3"></div>
              </div>
            </div>

            <div className="row pb-4 px-4 text-center">
              <div className="col-lg-6">
                <div className="mt-2 mb-2">
                  <p className="fs-5"><strong>Proficient Frontend:</strong></p>
                  <p>
                    <img className="s-4-icons" title="JavaScript" alt="JavaScript" src="/assets/external/techicons.dev/JavaScript.svg" />
                    <img className="s-4-icons" title="TypeScript" alt="TypeScript" src="/assets/external/techicons.dev/TypeScript.svg" />
                    <img className="s-4-icons" title="JQuery" alt="JQuery" src="/assets/external/techicons.dev/jQuery.svg" />
                    <br />
                    <img className="s-4-icons" title="Bootstrap" alt="Bootstrap" src="/assets/external/techicons.dev/Bootstrap.svg" />
                    <img className="s-4-icons" title="React" alt="React" src="/assets/external/techicons.dev/React.svg" />
                    <img className="s-4-icons" title="Vue" alt="Vue" src="/assets/external/techicons.dev/Vue.js.svg" />
                  </p>
                </div>
                <div className="mt-2 mb-2">
                  <p className="fs-5"><strong>Proficient Browser:</strong></p>
                  <p>
                    <img className="s-4-icons" title="Firefox" alt="Firefox" src="/assets/external/svgrepo.com/378802.svg" />
                    <img className="s-4-icons" title="Chrome" alt="Chrome" src="/assets/external/svgrepo.com/349318.svg" />
                    <img className="s-4-icons" title="Edge" alt="Edge" src="/assets/external/svgrepo.com/378791.svg" />
                  </p>
                </div>
                <div className="mt-2 mb-2">
                  <p className="fs-5"><strong>Proficient System:</strong></p>
                  <p>
                    <img className="s-4-icons" title="Ubuntu" alt="Ubuntu" src="/assets/external/techicons.dev/Ubuntu.svg" />
                    <img className="s-4-icons" title="Debian" alt="Debian" src="/assets/external/techicons.dev/Debian.svg" />
                    <img className="s-4-icons" title="FreeBSD" alt="FreeBSD" src="/assets/external/svgrepo.com/355027.svg" />
                  </p>
                </div>
                <div className="mt-2 mb-2">
                  <p className="fs-5"><strong>Proficient IDE:</strong></p>
                  <p>
                    <img className="s-4-icons" title="VSCode" alt="VSCode" src="/assets/external/techicons.dev/Visual-Studio-Code-(VS-Code).svg" />
                    <img className="s-4-icons" title="Eclipse" alt="Eclipse" src="/assets/external/techicons.dev/Eclipse-IDE.svg" />
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mt-2 mb-2">
                  <p className="fs-5"><strong>Proficient Backend:</strong></p>
                  <p>
                    <img className="s-4-icons" title="C#" alt="C#" src="/assets/external/techicons.dev/CSharp.svg" />
                    <img className="s-4-icons" title=".NET" alt=".NET" src="/assets/external/techicons.dev/DotNET.svg" />
                    <img className="s-4-icons" alt="" src="/assets/about/empty.svg" />
                    <br />
                    <img className="s-4-icons" title="Node.js" alt="Node.js" src="/assets/external/techicons.dev/Node.js.svg" />
                    <img className="s-4-icons" title="Nuxt" alt="Nuxt" src="/assets/external/techicons.dev/Nuxt-JS.svg" />
                    <img className="s-4-icons" title="Mongoose" alt="Mongoose" src="/assets/external/techicons.dev/Mongoose.js.svg" />
                    <br />
                    <img className="s-4-icons" title="Java" alt="Java" src="/assets/external/techicons.dev/Java.svg" />
                    <img className="s-4-icons" title="Spring" alt="Spring" src="/assets/external/techicons.dev/Spring.svg" />
                    <img className="s-4-icons" title="Tomcat" alt="Tomcat" src="/assets/external/techicons.dev/Apache-Tomcat.svg" />
                    <br />
                    <img className="s-4-icons" title="C++" alt="C++" src="/assets/external/techicons.dev/CPlusPlus.svg" />
                    <img className="s-4-icons" title="Boost" alt="Boost" src="/assets/about/boost-vec-white.svg" />
                    <img className="s-4-icons" alt="" src="/assets/about/empty.svg" />
                    <br />
                    <img className="s-4-icons" title="Python" alt="Python" src="/assets/external/techicons.dev/Python.svg" />
                    <img className="s-4-icons" title="Flask" alt="Flask" src="/assets/external/techicons.dev/Flask.svg" />
                    <img className="s-4-icons" alt="" src="/assets/about/empty.svg" />
                    <br />
                  </p>
                </div>
                <div className="mt-2 mb-2">
                  <p className="fs-5"><strong>Proficient Database:</strong></p>
                  <p>
                    <img className="s-4-icons" title="MySQL" alt="MySQL" src="/assets/external/techicons.dev/MySQL.svg" />
                    <img className="s-4-icons" title="Redis" alt="Redis" src="/assets/external/techicons.dev/Redis.svg" />
                    <img className="s-4-icons" title="MongoDB" alt="MongoDB" src="/assets/external/techicons.dev/MongoDB.svg" />
                  </p>
                </div>
                <div className="mt-2 mb-2">
                  <p className="fs-5"><strong>Other Proficient Language:</strong></p>
                  <p>
                    <img className="s-4-icons" title="PHP" alt="PHP" src="/assets/external/techicons.dev/PHP.svg" />
                    <img className="s-4-icons" title="C" alt="C" src="/assets/external/techicons.dev/C.svg" />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="s-5" className="container za_section za_item_background-color_2 za_part_color_whitesmoke">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
                <p className="text-center fs-1 za_font_art_title">Interests</p>
              </div>
              <div className="s-5-divider-l0">
                <div className="s-5-divider-l1p1"></div>
                <div className="s-5-divider-l1p2">
                  <i className="bi bi-bookmark"></i>
                </div>
                <div className="s-5-divider-l1p3"></div>
              </div>
            </div>

            <div className="row za_item_padding-x_4rem">
              <div className="col-lg-6">
                <p className="fs-4 fw-bold mt-0 mb-2">Development</p>
                <p className="mt-2 mb-0">&#x1F4AA;I'm keeping learning new things.</p>
                <ul className="mt-0 mb-2">
                  <li>Travis-CI</li>
                  <li>WebAssembly</li>
                  <li>WordPress</li>
                  <li>Django</li>
                  <li>PostgreSQL</li>
                  <li>etc.</li>
                </ul>
              </div>
              <div className="col-lg-6">
                <p className="fs-4 fw-bold mt-0 mb-2">OPS</p>
                <p className="mt-2 mb-0">&#x1F310;I'm still interested in cyber security operations.</p>
                <ul className="mt-0 mb-2">
                  <li>Code Audit, Vulnerability scanning and remediation</li>
                  <li>Traffic monitoring and interception</li>
                  <li>Penetration Testing</li>
                  <li>etc.</li>
                </ul>
              </div>
            </div>

            <div className="d-none">
              <p className="mt-2 mb-2">
                Excels in developing web applications with front-end and back-end separation that enhance reliability and stability.
                Expertise in optimizing code for performance, integrating new features, and executing cross-cloud deployment to ensure system reliability.
              </p>
              <p className="mt-2 mb-2">
                Committed to continuous learning and improvement, and dedicated to delivering high-quality, innovative web solutions.
              </p>
              <i className="bi bi-check2"></i>
            </div>
          </div>

          <div id="s-7" className="za_section za_item_background-color_4">
            <div className="row pb-4">
              <div className="text-center col-12">
                <h1 id="s-7-1-h1" className="za_font_art_title py-1">Contact Me</h1>
              </div>
              <div className="col-lg-8 mx-auto">

                <div id="s-7-f" role="form" hidden={!section7_getContact} className={section7_getContact ? "za_font_art_7" : "d-none"}>
                  <div className="form-group form-floating my-3">
                    <input id="s-7-f_fn" type="text" className="form-control s-7-f-common bsor_label_bg-transparent" required={true}
                      placeholder="" data-error="First name is required." />
                    <label htmlFor="s-7-f_fn">First Name *</label>
                  </div>

                  <div className="form-group form-floating my-3">
                    <input id="s-7-f_ln" type="text" className="form-control s-7-f-common bsor_label_bg-transparent" required={true}
                      placeholder="" data-error="Last name is required." />
                    <label htmlFor="s-7-f_ln">Last Name *</label>
                  </div>

                  <div className="form-group form-floating my-3">
                    <input id="s-7-f_e" type="email" className="form-control s-7-f-common bsor_label_bg-transparent" required={true}
                      placeholder="" data-error="Valid email is required." />
                    <label htmlFor="s-7-f_e">Email *</label>
                  </div>

                  <div className="form-group form-floating my-3">
                    <select id="s-7-f_w" className="form-select s-7-f-common bsor_label_bg-transparent" required={true} defaultValue={0}
                      data-error="">
                      <option value={0} disabled className="za_color_transparent_grey">--Select--</option>
                      <option value={2}>Resume</option>
                      <option value={3}>HW & YZ LLP</option>
                      <option value={4}>POSHIM LLC</option>
                      <option value={5}>BYARTS LIMITED</option>
                      <option value={1}>Other</option>
                    </select>
                    <label htmlFor="s-7-f_w">How did you find me? *</label>
                  </div>

                  <div className="form-group form-floating my-3">
                    <textarea id="s-7-f_m" className="form-control s-7-f-common za_item_min-height_10rem bsor_label_bg-transparent" rows={4} required={true}
                      placeholder="" data-error="Please, leave me a message."></textarea>
                    <label htmlFor="s-7-f_m">Message *</label>
                  </div>

                  <button id="s-7-f_submit" onClick={submitContact}
                    className="btn btn-send btn-outline-primary za_s-7-3-btn pt-2 btn-block my-3">
                    <i className="bi bi-send"></i>
                    <span> Send Message</span>
                  </button>
                </div>

                <div id="s-7-f" role="form" hidden={section7_getContact} className={section7_getContact ? "d-none" :
                  "d-flex flex-column justify-content-center align-items-center za_item_border_1 mt-3"}>
                  <h2 className="za_font_art_1">I have received your message.</h2><br />
                  <h1 className="za_font_art_5">Thanks for your contact.</h1>
                </div>

              </div>
            </div>

            <div className="row s-7-e border-0 za_font_art_6">
              <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                <p className="fs-4">Location</p>
                <div className="fs-6 s-7-f-4-l-2 flex-column">
                  <p><i className="bi bi-geo-alt"></i>Perth, WA</p>
                  <p>Australia</p>
                </div>
              </div>

              <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                <p className="fs-4">Around the Web</p>
                <div className="d-flex justify-content-center align-items-center s-7-f-4-l-2">
                  <a target="_blank" rel="noreferrer" className="btn btn-outline-dark d-flex s-7-f-2-a-1" href="https://www.linkedin.com/in/steve-shire-8767942b6/">
                    <i className="bi bi-linkedin s-7-f-2-ico-1"></i>
                  </a>
                  <a target="_blank" rel="noreferrer" className="btn btn-outline-dark d-flex s-7-f-2-a-1" href="mailto:314159265358x@gmail.com">
                    <i className="bi bi-envelope-at s-7-f-2-ico-1"></i>
                  </a>
                  <a hidden={true} href="https://github.com/"><i className="bi bi-github s-7-f-2-ico-1 d-none"></i></a>
                </div>
              </div>

              <div className="col-lg-4 d-flex flex-column justify-content-center align-items-center">
                <p className="fs-4">About this website</p>
                <div className="fs-6 s-7-f-4-l-2">
                  <p>
                    <span >This website is powered by </span>
                    <img src="/assets/external/techicons.dev/Bootstrap.svg" alt="" title="Bootstrap 5.3" className="s_7_f_3_ico_max-size" />
                    <img src="/assets/external/techicons.dev/React.svg" alt="" title="React 18.3" className="s_7_f_3_ico_max-size" />
                    <img src="/assets/external/techicons.dev/DotNET.svg" alt="" title=".NET 8.0" className="s_7_f_3_ico_max-size" />
                    <img src="/assets/external/techicons.dev/MongoDB.svg" alt="" title="MongoDB 8.0" className="s_7_f_3_ico_max-size" />
                    <span>.</span>
                  </p>
                </div>
              </div>

              <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                <small>Copyright © Steve Shire 2024</small>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div id="misc_modal_root">

      <div className="modal fade" id="s-2-m-1" tabIndex={-1} aria-labelledby="s-2-m-1-h" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h6 className="modal-title fs-6 opacity-0" id="s-2-m-1-h">SUUD Project</h6>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h1 className="modal-title text-center za_font-weight_800 za_item_font-size_xx-large">SUUD Project</h1>
              <h1 className="modal-title text-center za_font-weight_800 fs-6">(Simplified Universal User Dashboard)</h1>
              <div className="s-2-divider-l0">
                <div className="s-2-divider-l1p1"></div>
                <div className="s-2-divider-l1p2">
                  <i className="bi bi-code-slash"></i>
                </div>
                <div className="s-2-divider-l1p3"></div>
              </div>
              <div id="s-2-m-1-s-1" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide-to="0" aria-label="Slide 1" className="active" aria-current="true"></button>
                  <button type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  <button type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide-to="3" aria-label="Slide 4"></button>
                  <button type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide-to="4" aria-label="Slide 5"></button>
                  <button type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide-to="5" aria-label="Slide 6"></button>
                  <button type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide-to="6" aria-label="Slide 7"></button>
                  <button type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide-to="7" aria-label="Slide 8"></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item carousel-dark active">
                    <img src="/assets/about/suud_s_3.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Home Page</h5>
                    </div>
                  </div>
                  <div className="carousel-item carousel-dark">
                    <img src="/assets/about/suud_s_1.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Authentication Management</h5>
                    </div>
                  </div>
                  <div className="carousel-item carousel-dark">
                    <img src="/assets/about/suud_s_2.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Login Devices</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/about/suud_s_4.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Register</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/about/suud_s_6.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Login</h5>
                    </div>
                  </div>
                  <div className="carousel-item carousel-dark">
                    <img src="/assets/about/suud_s_5.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Authorization Management</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/about/suud_s_7.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Site Management</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/about/suud_s_8.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">SSO Confirm Page</h5>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#s-2-m-1-s-1" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              <div>
                <p className="mt-2 mb-0 za_font-weight_800">Description</p>
                <p className="mt-0 mb-2 fw-normal">
                  <span>SUUD project is for enterprise users who would like to depoly Single Sign-On (SSO) features in ther website, which in this case for HW&amp;YZ.</span><br />
                  <span>It focused on user account authentication and authorization as it provides an universal login portal for websites in relation to better manage login activities and boost login security.</span><br />
                  <span>SUUD project is developed using TypeScript in Nuxt.js webpack with Nitro framework on server side and Vue on client side.</span>
                </p>
                <p className="mt-2 mb-0 za_font-weight_800">Features</p>
                <p className="mt-0 mb-0 fw-normal">SUUD ensured high levels of security and performance across both front-end and back-end layers, utilizing modern web technologies.</p>
                <ul className="mt-0 mb-0 fw-normal">
                  <li><span className="za_font-weight_800">1<sub>st</sub> Core Feature</span><span>: Authentication with plain password and optional OTP verification, and use one-time recovery code for account recovery.</span></li>
                  <li><span className="za_font-weight_800">2<sub>nd</sub> Core Feature</span><span>: Single Sign-On support, including site management and grant management, support OAuth like and SAML like API style.</span></li>
                  <li><span className="za_font-weight_800">Side Feature</span><span>: A chatroom for every user chat together, based on CrossWS.</span></li>
                  <li><span className="za_font-weight_800">Side Feature</span><span>: Login session management for registered devices.</span></li>
                  <li><span className="za_font-weight_800">Side Feature</span><span>: Built-In image storage, for user avatar, site icon and image sharing service.</span></li>
                  <li><span className="za_font-weight_800">Side Feature</span><span>: User dashboard with all utilities and links.</span></li>
                  <li><span className="za_font-weight_800">Side Feature</span><span>: Voting system.</span></li>
                </ul>
                <p className="mt-0 mb-2 fw-normal">
                  <span>SUUD project is now in </span><span className="za_font-weight_800">production environment</span><span> as project manager required.</span><br />
                  <span>You can </span><span className="za_font-weight_800">try it</span><span> with the button below </span><i className="bi bi-hand-index za_item_rotate_180deg"></i>
                </p>
                <p className="mt-2 mb-0 za_font-weight_800">Tech Stack</p>
                <p className="mt-0 mb-2 fw-normal">
                  <img className="za_item_hw_3rem" title="Nuxt 3" alt="Nuxt 3" src="/assets/external/techicons.dev/Nuxt-JS.svg" />
                  <img className="za_item_hw_3rem" title="Bootstrap 5.3" alt="Bootstrap 5.3" src="/assets/external/techicons.dev/Bootstrap.svg" />
                  <img className="za_item_hw_3rem" title="Vue 3" alt="Vue 3" src="/assets/external/techicons.dev/Vue.js.svg" />
                  <img className="za_item_hw_3rem" title="NodeJS 20" alt="NodeJS 20" src="/assets/external/techicons.dev/Node.js.svg" />
                  <img className="za_item_hw_3rem" title="Mongoose 8" alt="Mongoose 8" src="/assets/external/techicons.dev/Mongoose.js.svg" />
                  <img className="za_item_hw_3rem" title="MongoDB 7.0" alt="MongoDB 7.0" src="/assets/external/techicons.dev/MongoDB.svg" />
                </p>
              </div>
            </div>
            <div className="modal-footer border-0 d-flex flex-column justify-content-center align-items-center">
              <button type="button" className="d-none btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="d-none btn btn-primary">Save changes</button>
              <a className="btn btn-outline-primary" target="_blank" rel="noreferrer" href="https://suud.net">
                <i className="bi bi-box-arrow-up-right za_item_margin-right_05rem"></i>
                <span className="ml-1">Access Service</span>
              </a>
              <div className="btn-group">
                <a className="btn btn-outline-primary" target="_blank" rel="noreferrer"
                  href="https://github.com/VectorLab/suud-site-cb">
                  <i className="bi bi-github za_item_margin-right_05rem"></i>
                  <span className="ml-1">OAuth like API example</span>
                </a>
                <a className="btn btn-outline-primary" target="_blank" rel="noreferrer"
                  href="https://github.com/VectorLab/suud-site-cert">
                  <i className="bi bi-github za_item_margin-right_05rem"></i>
                  <span className="ml-1">SAML like API example</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="s-2-m-2" tabIndex={-1} aria-labelledby="s-2-m-2-h" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h1 className="modal-title fs-6 opacity-0" id="s-2-m-2-h">HW &amp; YZ</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h1 className="modal-title text-center za_font-weight_800 za_item_font-size_xx-large">HW &amp; YZ</h1>
              <h1 className="modal-title text-center za_font-weight_800 fs-6">Official Website</h1>
              <div className="s-2-divider-l0">
                <div className="s-2-divider-l1p1"></div>
                <div className="s-2-divider-l1p2">
                  <i className="bi bi-code-slash"></i>
                </div>
                <div className="s-2-divider-l1p3"></div>
              </div>
              <div id="s-2-m-2-s-1" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#s-2-m-2-s-1" data-bs-slide-to="0" aria-label="Slide 1" className="active" aria-current="true"></button>
                  <button type="button" data-bs-target="#s-2-m-2-s-1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="/assets/about/hwyz_s_1.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Home Page</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/about/hwyz_s_2.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800 text-secondary">Dashboard Page</h5>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#s-2-m-2-s-1" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#s-2-m-2-s-1" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              <div>
                <p className="mt-2 mb-0 za_font-weight_800">Description</p>
                <p className="mt-0 mb-2 fw-normal">
                  <span>Contributed to the HW&amp;YZ&apos;s official website by integrating a robust login system module, collaborating effectively with team members to ensure seamless functionality.</span>
                </p>
                <p className="mt-2 mb-0 za_font-weight_800">Features</p>
                <p className="mt-0 mb-0 fw-normal">
                  Intergrate the HW&amp;YZ with SUUD authentication system.
                </p>
                <ul className="mt-0 mb-0 fw-normal">
                  <li><span>Connect HW&amp;YZ to SUUD SSO authentication system, click Dashboard button in the up right corner to login through SUUD.</span></li>
                  <li><span>Session management and store module for HW&amp;YZ.</span></li>
                </ul>
                <p className="mt-0 mb-2 fw-normal">
                  <span>HW&amp;YZ project is now in </span><span className="za_font-weight_800">production environment</span><span> .</span><br />
                  <span>You can </span><span className="za_font-weight_800">try it</span><span> with the button below </span><i className="bi bi-hand-index za_item_rotate_180deg"></i>
                </p>
                <p className="mt-2 mb-0 za_font-weight_800">Tech Stack</p>
                <p className="mt-0 mb-2 fw-normal">
                  <img className="za_item_hw_3rem" title="Nuxt 3" alt="Nuxt 3" src="/assets/external/techicons.dev/Nuxt-JS.svg" />
                  <img className="za_item_hw_3rem" title="Bootstrap 5.3" alt="Bootstrap 5.3" src="/assets/external/techicons.dev/Bootstrap.svg" />
                  <img className="za_item_hw_3rem" title="Vue 3" alt="Vue 3" src="/assets/external/techicons.dev/Vue.js.svg" />
                  <img className="za_item_hw_3rem" title="NodeJS 20" alt="NodeJS 20" src="/assets/external/techicons.dev/Node.js.svg" />
                </p>
              </div>
            </div>
            <div className="modal-footer border-0 d-flex flex-column justify-content-center align-items-center">
              <button type="button" className="d-none btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="d-none btn btn-primary">Save changes</button>
              <a className="btn btn-outline-primary" target="_blank" rel="noreferrer" href="https://hwyz.uk">
                <i className="bi bi-box-arrow-up-right za_item_margin-right_05rem"></i>
                <span className="ml-1">Access Service</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="s-2-m-3" tabIndex={-1} aria-labelledby="s-2-m-3-h" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h6 className="modal-title fs-6 opacity-0" id="s-2-m-3-h">Improved CyNER</h6>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h1 className="modal-title text-center za_font-weight_800 za_item_font-size_xx-large">Improved CyNER</h1>
              <h1 className="modal-title text-center za_font-weight_800 fs-6">(Cybersecurity Named Entity Recognition)</h1>
              <div className="s-2-divider-l0">
                <div className="s-2-divider-l1p1"></div>
                <div className="s-2-divider-l1p2">
                  <i className="bi bi-code-slash"></i>
                </div>
                <div className="s-2-divider-l1p3"></div>
              </div>
              <div id="s-2-m-3-s-1" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                  <button type="button" data-bs-target="#s-2-m-3-s-1" data-bs-slide-to="0" aria-label="Slide 1" className="active" aria-current="true"></button>
                  <button type="button" data-bs-target="#s-2-m-3-s-1" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item carousel-dark active">
                    <img src="/assets/about/cyner_s_1.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Result Demo</h5>
                    </div>
                  </div>
                  <div className="carousel-item carousel-dark">
                    <img src="/assets/about/cyner_s_2.png" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-none d-md-block">
                      <h5 className="za_font-weight_800">Training Dataset Demo</h5>
                    </div>
                  </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#s-2-m-3-s-1" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#s-2-m-3-s-1" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              <div>
                <p className="mt-2 mb-0 za_font-weight_800">Description</p>
                <p className="mt-0 mb-2 fw-normal">
                  <span>Improved CyNER system (Cybersecurity Named Entity Recognition).</span><br />
                  <span>Based on a new customized model and other existing pretrained models with improvements.</span><br />
                  <span>New custom model has been trained by using preprocessed 2500 sentences from BioCreative-V-CDR-Corpus dataset, and shared 1/6 Nvidia A40 GPU for trainning.</span>
                </p>
                <p className="mt-2 mb-0 za_font-weight_800">Features</p>
                <p className="mt-0 mb-0 fw-normal">This version of CyNER adds a new customized Bi-LSTM PyTorch model, mreged and improved the exist functionality in original CyNER, and expose a web API for remote call.</p>
                <ul className="mt-0 mb-2 fw-normal">
                  <li><span className="za_font-weight_800">1<sub>st</sub> Core Feature</span><span>: New custom model based on Bi-LSTM with train and predict ability, and be able to save and load trained state.</span></li>
                  <li><span className="za_font-weight_800">2<sub>nd</sub> Core Feature</span><span>: Unified web API call based on Flask, send sentences in request and response all detected keywords in JSON format.</span></li>
                  <li><span className="za_font-weight_800">Side Feature</span><span>: Unified call handler, merge result from each model.</span></li>
                  <li><span className="za_font-weight_800">Side Feature</span><span>: New launch script for environment detection, dependency installation and launching the secvice.</span></li>
                  <li><span className="za_font-weight_800">Side Feature</span><span>: Improved heuristics model, changed Regex and its priority to detect more accurate.</span></li>
                </ul>
                <p className="mt-2 mb-0 za_font-weight_800">Tech Stack</p>
                <p className="mt-0 mb-2 fw-normal">
                  <img className="za_item_hw_3rem" title="Flask 2" alt="Flask 2" src="/assets/external/techicons.dev/Flask.svg" />
                  <img className="za_item_hw_3rem" title="PyTorch 2" alt="PyTorch 2" src="/assets/external/techicons.dev/PyTorch.svg" />
                  <img className="za_item_hw_3rem" title="TensorFlow 2" alt="TensorFlow 2" src="/assets/external/techicons.dev/TensorFlow.svg" />
                </p>
              </div>
            </div>
            <div className="modal-footer border-0 d-flex flex-column justify-content-center align-items-center">
              <button type="button" className="d-none btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="d-none btn btn-primary">Save changes</button>
              <a className="btn btn-outline-primary" target="_blank" rel="noreferrer" href="https://github.com/314159265358x/ECUCyNER">
                <i className="bi bi-github za_item_margin-right_05rem"></i>
                <span className="ml-1">View Source</span>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>

  </>);
};

export default About;
