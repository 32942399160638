import { EventEmitter } from "./event_emitter";
import { LangFile } from "./lang_file";
import { detectDefaultLang } from "./nuxt_i18n_part";

// private
const glob_ins_tag: string = "__glob_obj_" + crypto_rand_hex(8) + "__";
const glob_key_tag: string = "__glob_key_86f667e8092988c8__";
const glob_api_path: string = "/api";
const glob_sso_site: string = "66f942e782ab48e331fb313a";






export function getWindow(): any {
    return (window as any);
};

export function event_setcanelled(ev: DragEvent | Event) {
    ev.preventDefault();
};

export interface ClientCommonEvent {
    cc: ClientCommon;
    type: string;
};

export function sso_suud_start_login() {
    const v1 = new URL("/sso", "https://suud.net");
    v1.searchParams.set("s", glob_sso_site);
    v1.searchParams.set("t", "cb");
    v1.searchParams.append("p", "1");
    v1.searchParams.append("p", "2");
    v1.searchParams.append("p", "4");
    {
        const v2 = new URL("/suud", window.location.href);
        v1.searchParams.set("cb", v2.toString());
    }
    window.location.assign(v1.toString());
};

export class ClientCommon {
    state_documentisready: boolean = false;
    callback_eventbus: EventEmitter<'load' | 'beforeunload' | 'langchange', ClientCommonEvent> = new EventEmitter();

    async init(): Promise<void> {
        getWindow()[glob_ins_tag] = this;
        window.addEventListener('load', () => {
            getWindow()[glob_ins_tag].onLoad();
        });
        window.addEventListener('beforeunload', (event) => {
            getWindow()[glob_ins_tag].onBeforeUnload();
        });
        await Promise.all([
            this.#lang_initFromUrl("/assets/lang/index.json"),
            this.#fetchLoginData()
        ]);








        if (typeof this.user_baseinfo == "object" && typeof this.user_baseinfo.l == "number") {
            await this.lang_set(this.user_baseinfo.l).catch(() => this.lang_set());
        } else {
            this.lang_set();
        }
    }

    onLoad(): void {
        this.state_documentisready = true;
        this.callback_eventbus.emit('load', { type: 'load', cc: this });




    }
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
     */
    onBeforeUnload(): void {
        this.callback_eventbus.emit('beforeunload', { type: 'beforeunload', cc: this });






    }

    user_baseinfo: void | any = undefined;

    get user_name() {
        if (null == this.user_baseinfo) {
            return undefined;
        }
        return this.user_baseinfo.n;
    }

    get user_lang() {
        if (null == this.user_baseinfo) {
            return undefined;
        }
        return this.user_baseinfo.l;
    }

    get api_url() {
        return glob_api_path;
    }

    async signout() {
        const v1 = this.session_key;
        if (undefined === v1) {
            return;
        }
        const v2 = await fetch(this.api_url + "/client/auth/signout", {
            method: "POST",
            body: v1
        });
        if (v2.ok) {
            this.session_key = undefined;
            this.user_baseinfo=undefined;
        }
    }

    async refreshLoginData() {
this.user_baseinfo=undefined;
await this.#fetchLoginData();
    }

    async #fetchLoginData() {
        if (undefined === this.session_key) {
            this.user_baseinfo = undefined;
            return;
        }
        let v1 = await fetch(this.api_url + "/client/user/baseinfo", {
            "method": "POST",
            "body": this.session_key
        });
        if (!v1.ok) {
            console.log("session invalid");
            this.session_key = undefined;
            this.user_baseinfo = undefined;
            return;
        }
        let v2 = await v1.json();
        if (typeof v2 != "object") {
            this.user_baseinfo = undefined;
            return;
        }
        this.user_baseinfo = v2;
    }

    get session_key() {
        let v1 = localStorage.getItem(glob_key_tag);
        if (typeof v1 != "string" || v1.length === 0) {
            return undefined;
        }
        return v1;
    }

    set session_key(p1: void | string) {
        let v1 = p1;
        if (typeof v1 != "string" || v1.length === 0) {
            localStorage.removeItem(glob_key_tag);
        } else {
            localStorage.setItem(glob_key_tag, v1);
        }
    }

    lang_maketranslator() {
        const v1 = this;
        let r = function (p1: string) {
            const v2 = v1.lang_current;
            if (undefined === v2) {
                return p1;
            }
            return v2.translate(p1) || p1;
        };
        return r;
    }

    async #lang_set_post(p1: LangFile) {
        this.lang_current = p1;
        await p1.onEnable();
        this.callback_eventbus.emit("langchange", { type: 'langchange', cc: this });
    }

    async lang_set(p1: void | string | number | LangFile) {
        if (p1 instanceof LangFile) {
            await this.#lang_set_post(p1);
            return;
        }
        if (typeof p1 == "string" && this.lang_indexLang.has(p1)) {
            let v1 = this.lang_indexLang.get(p1);
            if (undefined !== v1) {
                await this.#lang_set_post(v1);
                return;
            }
        }
        if (typeof p1 == "number" && this.lang_indexId.has(p1)) {
            let v1 = this.lang_indexId.get(p1);
            if (undefined !== v1) {
                await this.#lang_set_post(v1);
                return;
            }
        }

        const v3 = new Set(this.lang_indexLang.keys());
        const v4 = detectDefaultLang(v3) || detectDefaultLang(v3, "en-US", [
            "en-US", "en-CA", "en-UK", "en-AU", "zh-TW", "zh-SG", "zh-HK", "zh-CN"
        ]);// should not null here
        if (undefined === v4) {
            console.warn("unable to find a valid lang file", v3.keys());
            return;
        }
        const v5 = this.lang_indexLang.get(v4);
        if (undefined !== v5 && undefined === this.lang_current) {
            await this.#lang_set_post(v5);
            return;
        }
    }

    lang_indexId: Map<number, LangFile> = new Map();
    lang_indexLang: Map<string, LangFile> = new Map();
    lang_current: void | LangFile = undefined;

    async #lang_initFromUrl(p1: string) {
        const v1 = await fetch(p1);
        const v2: any[] = await v1.json();
        this.#lang_initFromData(v2);
    }

    #lang_initFromData(p1: any[]) {
        this.lang_indexId.clear();
        this.lang_indexLang.clear();

        for (const v1 of p1) {
            const v2 = new LangFile(v1);
            if (undefined !== v2.id) {
                if (this.lang_indexId.has(v2.id)) {
                    console.error("id index duplicated", v2.id);
                }
                this.lang_indexId.set(v2.id, v2);
            }
            if (undefined !== v2.language) {
                for (const v3 of v2.language) {
                    if (this.lang_indexLang.has(v3)) {
                        console.error("lang index duplicated", v3);
                    }
                    this.lang_indexLang.set(v3, v2);
                }
            }
        };
    }

};

export function crypto_rand_hex(size: number): string {
    const array = new Uint8Array(size);
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};

export async function getOrLoad(): Promise<ClientCommon> {
    const v1 = getWindow()[glob_ins_tag];
    if (v1 instanceof ClientCommon) {
        return v1;
    } else if (undefined !== v1) {
        console.log("error: global key used", glob_ins_tag, v1);
        throw new Error("error: global key used: " + glob_ins_tag);
    }
    const v2 = new ClientCommon();
    getWindow()[glob_ins_tag] = v2;
    await v2.init();
    return v2;
};

/* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/log */
export function math_log(x:number, y:number) {
  return Math.log(y) / Math.log(x);
};
